import React from 'react';
import Login from './component/login/login';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom.css';
import Main from './component/layout/main'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import NotFound from './component/layout/notfound';
import PrivateRoute from './services/PrivateRoute'
import Chatbot from './component/layout/chatbot';
import LinkPage from './component/layout/linkpage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute><Main /></PrivateRoute>} />
          <Route path="/linkpage" element={<PrivateRoute><LinkPage /></PrivateRoute>} />
          <Route path="/chatbot" element={<PrivateRoute><Chatbot /></PrivateRoute>} />
          <Route path="*" element={<NotFound/>}/>
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
