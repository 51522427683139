import React, { useState } from 'react';
import Header from './header';
import Asideleft from './asideleft';
import Documentlist from './documentlist';
import LinkPage from './linkpage';

function Main() {
  const [isToggled, setIsToggled] = useState(false);
  // console.log('userdata==',localStorage.getItem('userData'));
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  return (
    // <div className={toggle ? 'wrapper toggled-sidenav': 'wrapper'} onClick={()=> setToggle(!toggle)}>/
    <div className={isToggled ? 'wrapper toggled-sidenav': 'wrapper'}>
      <Header toggle={isToggled} onToggle={handleToggle} />
      <Asideleft />
      <Documentlist />
      
    </div>
  );
}

export default Main;