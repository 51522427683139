import {REACT_APP_API_URL,REACT_APP_API_KEY,REACT_APP_Document_URL} from './config'
import axios from 'axios';

const BASE_URL = REACT_APP_API_URL;

const headers = {
    Authorization: REACT_APP_API_KEY
  };

const apiService = axios.create({
  baseURL: BASE_URL,
});

export const loginapi = (url, data) => {
  return apiService.post(url, data,{ headers });
};

export const upload_docs_api = (url, data) => {
    return apiService.post(url, data,{ headers });
  };

export const getall_docs_api = (url, data) => {
return apiService.post(url, data,{ headers });
};

export const docs_delete = (url) => {
  return apiService.delete(url,{ headers });
};
