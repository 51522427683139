import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({  children }) => {
    const login = JSON.parse(localStorage.getItem('userData'))
    if (login) {
        return children
    }return <Navigate to='/' />
}

export default PrivateRoute;
