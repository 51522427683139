import { Col, Button, Row, Container, Card, Form, Image } from "react-bootstrap";
import logo from '../../assets/img/datapunch_logo.png';
import React, { useState } from 'react';
import {REACT_APP_API_URL,REACT_APP_API_KEY} from '../../services/config'
import { useNavigate } from 'react-router-dom';
import { loginapi } from "../../services/apiService";
import { openNotification } from '../../services/notification'
function Login() {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
      email: '',
      password: '',
    });

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };


  const handleSubmit = async (e) => {
      e.preventDefault();
      const bodydata = new FormData();
      const validationErrors = validateForm(formData);
      if (Object.keys(validationErrors).length === 0) {
      bodydata.append('email', formData.email);
      bodydata.append('password', formData.password);
      try {
          const response = await loginapi(REACT_APP_API_URL+'login', bodydata);
          if(response.status === 200 && response.data.status === true){
            openNotification(response.data.message,'success');
            localStorage.setItem('userData', JSON.stringify(response.data.data));
            navigate('/dashboard');
          }else{
            openNotification(response.data.message,'error');
          }
        } catch (error) {
          console.error('Login Error', error);
        }
      } else {
        setErrors(validationErrors);
      }
    };

  const validateForm = (data) => {
      const errors = {};
      if(!data.email){
        errors.email = 'Email is required';
      }else if(!isValidEmail(data.email)){
        errors.email = 'Invalid email format';
      }
      if(!data.password){
        errors.password = 'Password is required';
      }
      return errors;
  };
    
  const isValidEmail = (email) => {
      return email.includes('@');
  };


  return (
    <Container>
      <Row className="vh-100 d-flex justify-content-center align-items-center">
        <Col md={6} lg={5} xs={12}>
          <Card className="shadow login-signup">
            <Card.Body className="p-md-5">
              <div className="mb-3 mt-md-4">
                <div className="brand">
                  <Image src={logo} />
                  <p className=" mb-5">Please enter your login and password!</p>
                </div>
                <div className="mb-3">
                  <Form onSubmit={handleSubmit}>
                    
                    <Form.Group className="mb-3">
 
                      <Form.Control type="email" name="email" value={formData.email} placeholder="Enter email" onChange={handleChange} />
                      {errors.email && <span className="error">{errors.email}</span>}
                    </Form.Group>

                    <Form.Group className="mb-5">
     
                      <Form.Control type="password" name="password" value={formData.password} placeholder="Password" onChange={handleChange} />
                      {errors.password && <span className="error">{errors.password}</span>}
                    </Form.Group>
                    <Row className="d-flex align-items-center">
                      <Col md={6} lg={6} xs={12}>
                        <Button variant="secondary" type="submit">
                          Login
                        </Button>
                      </Col>
                      <Col md={6} lg={6} xs={12}>
                        <Form.Group className="text-end" controlId="formBasicCheckbox">
                          <p className="small mb-0">
                            <a href="#!">
                              Forgot password?
                            </a>
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                 
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;