import React from 'react';
import { Col, Button, Row, Container, Card, Form, Image } from "react-bootstrap";
import logo from '../../assets/img/hiplinklogo.png';

function NotFound() {
  return (
    <Container>
      <Row className="vh-100 d-flex justify-content-center align-items-center">
        <Col md={12} lg={12} xs={12}>
          <h1>Not Found</h1>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;