import React from 'react';
import Icon from 'react-icons-kit';
import {ic_menu_outline} from 'react-icons-kit/md'
import { Image, Button } from "react-bootstrap";
import logo from '../../assets/img/datapunch_logo.png';
import profile from '../../assets/img/profile-picture.jpeg';

function Header(props) {
  console.log('props: ', props)
  console.log('props.toggle: ', props.toggle)
  // const sendData = () => {
  //   props.sendDataToParent(props.toggle);
  // };
  return (
    <div className="header">
      <Button variant="flat" className="btn-toggle p-3" onClick={props.onToggle}>
        <Icon size={24} icon={ic_menu_outline} />
      </Button>
      <div className="brand">
        <Image src={logo} />
      </div>
      <div className="user-profile">
        <span className="picture">
          <Image src={profile} />
        </span>
        <span className="name">{JSON.parse(localStorage.getItem('userData')).name?JSON.parse(localStorage.getItem('userData')).name:''}</span>
      </div>
    </div>
  );
}

export default Header;