import {Button, Row, Col, Form, Image} from 'react-bootstrap';
import { Icon } from 'react-icons-kit';
import {ic_content_copy} from 'react-icons-kit/md';
import copy from 'clipboard-copy';
import boticon from '../../assets/img/boticon.png';
import profile from '../../assets/img/profile-picture.jpeg';
import React, { useEffect } from 'react';

function WidgetSnippet() {
  
global.lcsChatAppId= '231001lcspro';
  useEffect(() => {

    const script = document.createElement('script');
    script.src = '/js-chat/lcs-chatbot.js';

    script.onload = () => {
      lsCallback();
    };

    const divContainer = document.getElementById('lcs-chat-container');
    divContainer.classList.add('lcs-show');

    divContainer.parentNode.insertBefore(script, divContainer.nextSibling);

    function lsCallback() {      
      // Ensure that ChatBoxLibrary is available in your React app
      // Call ChatBoxLibrary functions as needed
      global.ChatBoxLibrary.appendChatBox("lcs-chat-container")
    }

    // Cleanup: remove the script when the component unmounts
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);


  const handleCopyClick = () => {
    const codeToCopy = `
    <div id="chatbot" style="background: none; position: fixed; bottom: 65px; float:none; width: 370px; height: 100%; max-height: 550px; margin: 0px; padding: 0px; min-height: 0px; right: 0; left: auto; z-index: 999999999;">
    <iframe scrolling="no" allow="autoplay; encrypted-media; fullscreen;" frameborder="0" src="https://billdemo2.dealopia.com/js-chat/index.html?enablejsapi=True"  width="100%" height="100%"></iframe></div>
    `;

    copy(codeToCopy)
    .then(() => {
      alert('Code copied to clipboard!');
    })
    .catch((error) => {
      console.error('Copy failed:', error);
    });
  };
  return (
    <div className="right-panel">
      <div className="page-head">
        <h2 className="page-title">Bot Widget Code</h2>
      </div>
      <Row className="sample-chat">
        <Col lg={6} md={6} sm={12}>          
          <code>
            <Button variant="flat" className="p-2 copy" onClick={handleCopyClick} ><Icon size={20} icon={ic_content_copy} /></Button>
            &lt;div id="chatbot" style="background: none; position: fixed; bottom: 65px; float:none; width: 370px; height: 100%; max-height: 550px; margin: 0px; padding: 0px; min-height: 0px; right: 0; left: auto; z-index: 999999999;"&gt;
            &lt;iframe scrolling="no" allow="autoplay; encrypted-media; fullscreen;" frameborder="0" src="https://billdemo2.dealopia.com/js-chat/index.html?enablejsapi=True"  width="100%" height="100%"&gt;&lt;iframe&gt;&lt;/div&gt;
          </code>
        </Col>
        <Col lg={6} md={6} sm={12}>
          {/*<div class="chatbox">
            <div class="chatheader">
              <h2>Chatbot</h2>
              <p>Powered by Hiplink</p>
            </div>
            <div class="chat-content">
              <div className="messages">
                <div class="message-box">
                  <span class="profile">
                    <Image src={boticon} />
                  </span>
                  <p><span class="block font-bold text-gray-700">AI </span>Hi, how can I help you today?</p>
                </div>
                <div class="message-box me">
                  <span class="profile">
                    <Image src={profile} />
                  </span>
                  <p class="leading-relaxed"><span class="block font-bold text-gray-700">You </span>fewafef</p>
                </div>
                <div class="message-box">
                  <span class="profile">
                    <Image src={boticon} />
                  </span>
                  <p class="leading-relaxed"><span class="block font-bold text-gray-700">AI </span>Sorry, I couldn't find any
                    information in the documentation about that. Expect answer to be less accurateI could not find the answer to
                    this in the verified sources.</p>
                </div>

                <div class="message-box">
                  <span class="profile">
                    <Image src={boticon} />
                  </span>
                  <p><span class="block font-bold text-gray-700">AI </span>Hi, how can I help you today?</p>
                </div>
                <div class="message-box me">
                  <span class="profile">
                    <Image src={profile} />
                  </span>
                  <p class="leading-relaxed"><span class="block font-bold text-gray-700">You </span>fewafef</p>
                </div>
                <div class="message-box">
                  <span class="profile">
                    <Image src={boticon} />
                  </span>
                  <p class="leading-relaxed"><span class="block font-bold text-gray-700">AI </span>Sorry, I couldn't find any
                    information in the documentation about that. Expect answer to be less accurateI could not find the answer to
                    this in the verified sources.</p>
                </div>

                <div class="message-box">
                  <span class="profile">
                    <Image src={boticon} />
                  </span>
                  <p><span class="block font-bold text-gray-700">AI </span>Hi, how can I help you today?</p>
                </div>
                <div class="message-box me">
                  <span class="profile">
                    <Image src={profile} />
                  </span>
                  <p class="leading-relaxed"><span class="block font-bold text-gray-700">You </span>fewafef</p>
                </div>
                <div class="message-box">
                  <span class="profile">
                    <Image src={boticon} />
                  </span>
                  <p class="leading-relaxed"><span class="block font-bold text-gray-700">AI </span>Sorry, I couldn't find any
                    information in the documentation about that. Expect answer to be less accurateI could not find the answer to
                    this in the verified sources.</p>
                </div>
              </div>
            </div>
            <div class="chatfooter">
              <Form>
                <Form.Control type="email" placeholder="Type your message" />
                <Button>Send</Button>
              </Form>
            </div>
          </div>*/}
          <div id="lcs-chat-container"></div>
        </Col>
      </Row>
    </div>
  );
}

export default WidgetSnippet;