import { useState } from 'react';
import {Button, Table, Form, Row, Col, Modal} from 'react-bootstrap';
import { Icon } from 'react-icons-kit';
import {ic_delete_twotone} from 'react-icons-kit/md';
import Asideleft from './asideleft';
import Header from './header';


function LinkPage() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="wrapper">
      <Header />
      <Asideleft />
      
      <div className="right-panel">
        <div className="page-head">
          <h2 className="page-title">Links</h2>
          <Button variant="primary" onClick={handleShow}>Add Link</Button>
        </div>
        <Row>
          <Col sm={12}>
            <div className="documentlist">
            <Table>
              <thead>
                <tr>
                  <th width="75">Sr. No.</th>
                  <th>File Name</th>
                  <th>Added Date</th>
                  <th width="100">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center p-5" colSpan={4}><span className="text-secondary">No link Added Yet</span></td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td>01</td>
                  <td><a href="" target='_blank'>Link URL</a></td>
                  <td>Date Time</td>
                  <td className="actions">
                    <Button variant="danger" className="px-2 py-1"><Icon icon={ic_delete_twotone} size={20} /></Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            </div>
          </Col>
        </Row>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Your Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-4">
            <Form.Control type="email" placeholder="Enter Your Link Here" />
          </Form.Group>
          <Form.Group className="text-center mb-3">
            <Button variant="primary" onClick={handleClose}>Submit</Button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LinkPage;