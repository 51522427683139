import React, { useState } from 'react';
import { Icon } from 'react-icons-kit';
import { useNavigate,Link } from 'react-router-dom';
import {ic_playlist_add_check, ic_power_settings_new, ic_add_link} from 'react-icons-kit/md';
import {link} from 'react-icons-kit/icomoon';
import {octoface} from 'react-icons-kit/oct/octoface';
import { openNotification } from '../../services/notification';

function Asideleft() {
  
  const [isActive,setActive] = useState('dashboard')

  console.log('active menu==',isActive)
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('userData');
    openNotification('Logout Successfully !','success');
    navigate('/');
  };

  return (
    <div className="sidenav">
      <Link className={`${isActive === "dashboard" ? 'active' : ''}`} to="/dashboard" onClick={() => setActive("dashboard")}>
        <Icon icon={ic_playlist_add_check} size={24} /><span>Upload File</span>
      </Link>

      <Link to="/chatbot" className={`${isActive === "widgetcode" ? 'active' : ''}`} onClick={() => setActive("widgetcode")}>
        <Icon icon={octoface} size={24} /><span>Bot Code</span>
      </Link>
      <a href="" onClick={handleLogout}>
        <Icon icon={ic_power_settings_new} size={24} /><span>Signout</span>
      </a>
    </div>
    
  );
}

export default Asideleft;
