import React from 'react';
import {Button, Table, Form} from 'react-bootstrap';
import { Icon } from 'react-icons-kit';
import {ic_delete_twotone, ic_mode_outline} from 'react-icons-kit/md';
function Asideright() {
  return (
    <div className="right-panel">
      <div className="page-head">
        <h2 className="page-title">List of Documents</h2>
        <Form.Group controlId="formFile" className="btn btn-secondary">
          <Form.Label>Upload Document</Form.Label>
          <Form.Control type="file" />
        </Form.Group>
      </div>
      <Table className="documentlist">
        <thead>
          <tr>
            <th width="75">Sr. No.</th>
            <th>File Name</th>
            <th width="100">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center p-5" colSpan={3}><span className="text-secondary">File Not Uploaded Yet</span></td>
          </tr>
          <tr>
            <td>1</td>
            <td>Mark</td>
            <td className="actions">
              <Button variant="primary" className="px-2 py-1 me-1"><Icon icon={ic_mode_outline} size={20} /></Button>
              <Button variant="danger" className="px-2 py-1"><Icon icon={ic_delete_twotone} size={20} /></Button>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Jacob</td>
            <td className="actions">
              <Button variant="primary" className="px-2 py-1 me-1"><Icon icon={ic_mode_outline} size={20} /></Button>
              <Button variant="danger" className="px-2 py-1"><Icon icon={ic_delete_twotone} size={20} /></Button>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Larry the Bird</td>
            <td className="actions">
              <Button variant="primary" className="px-2 py-1 me-1"><Icon icon={ic_mode_outline} size={20} /></Button>
              <Button variant="danger" className="px-2 py-1"><Icon icon={ic_delete_twotone} size={20} /></Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default Asideright;