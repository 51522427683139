import React from 'react';
import Header from './header';
import Asideleft from './asideleft';
import Asideright from './asideright';

import Documentlist from './documentlist'
import WidgetSnippet from './widget_snippet'

function Chatbot() {

  console.log('userdata==',localStorage.getItem('userData'));
  return (
    <div className="wrapper">
      <Header />
      <Asideleft />
      <WidgetSnippet />
    </div>
  );
}

export default Chatbot;
