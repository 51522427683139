import {Button, Table, Form, Row, Col} from 'react-bootstrap';
import { Icon } from 'react-icons-kit';
import {ic_delete_twotone} from 'react-icons-kit/md';
import React, { useEffect, useState } from 'react';
import {REACT_APP_API_URL,REACT_APP_Document_URL} from '../../services/config'

import { getall_docs_api,docs_delete,upload_docs_api } from "../../services/apiService";
import { openNotification } from '../../services/notification'


function Documentlist() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    id: ''
  });

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const bodydata = new FormData();
    bodydata.append('id', JSON.parse(localStorage.getItem('userData')).id);
    getall_docs_api(REACT_APP_API_URL+'getalldocs',bodydata).then((response) => {
      setData(response.data.data);
      setLoading(false);
    })
    .catch((error) => {
      setError(error);
      setLoading(false);
    });
  };

  if (loading) {
    return <div className="loader-container"><div className="loader"></div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleDelete = (id) => {
    setLoading(true);
    docs_delete(REACT_APP_API_URL+'singledata_delete/'+id).then((response) => {
      setLoading(false);
      openNotification(response.data.message,'success');
      fetchData();
    })
    .catch((error) => {
      console.error('Error deleting row:', error);
    });
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if(file) {
      handleUpload(file);
    }else{
      openNotification('Please Select Pdf.','error');
      return;
    }
  };

  const handleUpload = (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('pdf_file', file);
    formData.append('id', JSON.parse(localStorage.getItem('userData')).id);
    upload_docs_api(REACT_APP_API_URL+'upload_docs', formData).then((response) => {
      openNotification(response.data.message,'success');
      fetchData();
      setLoading(false); 
    })
    .catch((error) => {
      console.error('Error uploading file', error);
    });
  };

  return (
    <div className="right-panel">
      <div className="page-head">
        <h2 className="page-title">List of Documents</h2>
        <Form.Group controlId="formFile" className="btn btn-primary">
          <Form.Label>Upload Document</Form.Label>
          <Form.Control type="file" accept="application/csv" onChange={handleFileChange}/>
        </Form.Group>
      </div>
      <Row>
        <Col sm={12}>
          <div className="documentlist">
          <Table>
            <thead>
              <tr>
                <th width="75">Sr. No.</th>
                <th>File Name</th>
                <th>Uploaded Date</th>
                <th width="100">Actions</th>
              </tr>
            </thead>
            {data.length === 0 ? (
              <tbody>
                <tr>
                  <td className="text-center p-5" colSpan={4}><span className="text-secondary">File Not Uploaded Yet</span></td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {data.map((item,index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td><a href={REACT_APP_Document_URL+item.docs_name} target='_blank'>{item.docs_name}</a></td>
                    <td>{item.uploaded_at}</td>
                    <td className="actions">
                      <Button variant="danger" onClick={() => handleDelete(item.id)} className="px-2 py-1"><Icon icon={ic_delete_twotone} size={20} /></Button>
                    </td>
                  </tr>
                ))}
                
              </tbody>
            )}
          </Table>
          </div>
        </Col>
      </Row>
      
    </div>
  );
}

export default Documentlist;
